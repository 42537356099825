$font-family-sans-serif: monospace;
$font-family-serif: monospace;
$font-family-monospace: monospace;
$font-family-base: monospace;

$brand-primary:             #541C41;
$brand-success:             #66CB62;
$brand-info:                #339992;
$brand-warning:             #BED47D;
$brand-danger:              #B4763C;

@import 'bootstrap/scss/bootstrap';

.cryptohagen-navbar {
  background-color: $brand-primary;
  color: #fff;
  .nav-link {
    color: #fff;
  }
  .navbar-brand {
    color: lighten($brand-primary, 40%);
  }
}

body {
  padding-top: 50px;
}

section {
  padding-top: 50px;
}

.card-deck
{
  .card {
    max-width: 33%;
  }
}

.card-columns
{
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
}

footer
{
  background: $brand-info;
  margin-top: 50px;
  padding: 20px;
  a {
    color: #FFF;
  }
}

